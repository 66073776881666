import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";



const firebaseConfig = {
  apiKey: "AIzaSyDDrU2FsM-onX_Q4zSrQjAfz8oq9LgcVuY",
  authDomain: "flora-technik.firebaseapp.com",
  projectId: "flora-technik",
  storageBucket: "flora-technik.appspot.com",
  messagingSenderId: "736910518254",
  appId: "1:736910518254:web:59d5698ba50ccd5609d0ff",
  measurementId: "G-42DEQ817P5"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth();
export const storage = getStorage(app);
    