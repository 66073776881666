export const userInputs = [
    {
      id: "title",
      label: "Title",
      type: "text",
      placeholder: "",
    },

    {
      id: "subtitle",
      label: "Subtitle",
      type: "text",
      placeholder: "",
    },
    {
      id: "text",
      label: "Text",
      type: "textarea",
      rows: "30",
      placeholder: "",
    },
    {
      id: "date",
      label: "Datum",
      type: "date",
      placeholder: "",
    }
    
    
  ];
  
  export const productInputs = [
    {
      id: 1,
      label: "Title",
      type: "text",
      placeholder: "Apple Macbook Pro",
    },
    {
      id: 2,
      label: "Description",
      type: "text",
      placeholder: "Description",
    },
    {
      id: 3,
      label: "Category",
      type: "text",
      placeholder: "Computers",
    },
    {
      id: 4,
      label: "Price",
      type: "text",
      placeholder: "100",
    },
    {
      id: 5,
      label: "Stock",
      type: "text",
      placeholder: "in stock",
    },
  ];
  