import "./edit.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import { useEffect, useState } from "react";
import {
  doc,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "../../firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate, useLocation } from "react-router-dom";
import { setDoc } from "firebase/firestore";

const Edit2 = ({ inputs, title }) => {

  const [file, setFile] = useState("");
  const [data, setData] = useState({});
  const [per, setPerc] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const employee = location.state.employee;
 
console.log(employee.Beschreibung);
  useEffect(() => {
    // Fetch the existing item data using the ID from the URL or any other identifier
    console.log(location.pathname)
    const itemId = location.pathname.split('/').pop(); // Modify this based on your URL structure

    // Here, you would typically fetch the existing item's data from the database and populate the form fields
    // Example: Fetch item data using the itemId and update the 'data' state to pre-fill the form
  }, [location]);

  const uploadFile = () => {
    if (file) {
      const name = new Date().getTime() + file.name;
      const storageRef = ref(storage, name);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setPerc(progress);
        },
        (error) => {
          console.log(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setData((prev) => ({ ...prev, img: downloadURL }));
          });
        }
      );
    }
  };

  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    setData({ ...data, [id]: value });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    
    console.log("test");
    try {
      if (employee && employee.id) {
      await updateDoc(doc(db, "users", employee.id), {
        ...data,
        timeStamp: serverTimestamp(),
        
      });navigate(-1);
    }else {
      console.error("Data or data.id is undefined or null.");
    }} catch (err) {
      
      console.log(err);
    }
  };

  return (
    <div className="new">
      <Sidebar />
      <div className="newContainer">
        <Navbar />
        <div className="top">
          <h1>{title}
          </h1>
        </div>
        <div className="bottom">
          <div className="left">
            <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : employee.img
              }
              alt=""
            />
          </div>
          <div className="right">
            <form onSubmit={handleUpdate}>
              <div className="formInput">
                <label htmlFor="file">
                  Image: <DriveFolderUploadOutlinedIcon className="icon" />
                </label>
                <input
                  type="file"
                  id="file"
                  onChange={(e) => setFile(e.target.files[0])}
                />
              </div>
              
              {inputs.map((input) => (
                <div className="formInput" key={input.id}>
                  <label>{input.label}</label>
                  {input.type === 'textarea' ? ( 
                    <textarea
                      id={input.id}
                      placeholder={employee.beschreibung}
                      onChange={handleInput}
                      rows={20}
                      cols={40}
                      
                      defaultValue={employee[input.id]} 
                      ></textarea>
                  ) : (
                    <input
                      id={input.id}
                      type={input.type}
                      placeholder={employee[employee.id]}
                      onChange={handleInput}
                      defaultValue={employee[input.id]}
                    ></input>
                  )}
                </div>
              ))}
              <button type="submit">
                Update
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Edit2;