export const userColumns = [

  {
    field: "title",
    headerName: "Title",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <text>{params.row.title}</text>
          {params.row.name}
        </div>
      );
    },
  }, {
    field: "subtitle",
    headerName: "Subtitle",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <text>{params.row.subtitle}</text>
          {params.row.name}
        </div>
      );
    },
  },
  {
    field: "img",
    headerName: "Img",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img className="cellImg" src={params.row.img} alt="avatar" />
          {params.row.name}
        </div>
      );
    },
  },
  {
    field: "date",
    headerName: "Date",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <text>{params.row.date}</text>
          {params.row.name}
        </div>
      );
    },
  },

  
];
